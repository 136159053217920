// App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RedirectPage from './RedirectPage';
// Import other components as needed

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the root path */}
        <Route path="/" element={<RedirectPage />} />
        {/* Route for /app path */}
        <Route path="/app" element={<RedirectPage />} />
        {/* Define other routes as needed */}
        {/* Catch-all route for 404 */}
        <Route path="*" element={<RedirectPage />} />
      </Routes>
    </Router>
  );
}

export default App;
