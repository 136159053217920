import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RedirectPage = () => {
  const location = useLocation();

  // Define the app store URLs inside the component scope
  const appStoreURL = 'https://apps.apple.com/us/app/circles-verified/id1670176496'; // iOS App Store URL
  const playStoreURL = 'https://play.google.com/store/apps/details?id=com.renelabs.circlesocial'; // Android Play Store URL

  useEffect(() => {
    const uriScheme = 'circlesverified://'; // Custom URI scheme
    const path = window.location.pathname.replace('app/', ''); // Current path
    const search = window.location.search; // Query string (e.g., ?circleId=6806)
    const timeoutDuration = 500; // Duration to wait before redirecting
  
    const appStoreURL = 'https://apps.apple.com/us/app/circles-verified/id1670176496'; // iOS App Store URL
    const playStoreURL = 'https://play.google.com/store/apps/details?id=com.renelabs.circlesocial'; // Android Play Store URL
  
    const isIos = /iphone|ipod|ipad/i.test(navigator.userAgent); // iOS detection
    const isAndroid = /android/i.test(navigator.userAgent); // Android detection
  
    const startTime = Date.now();
  
    const openApp = () => {
      // Attempt to open the app
      window.location.href = uriScheme + path + search;
    };
  
    const redirectToAppStore = () => {
      window.location.href = appStoreURL;
    };
  
    const redirectToPlayStore = () => {
      window.location.href = playStoreURL;
    };
  
    const redirectToStore = () => {
      if (isIos) {
        redirectToAppStore();
      } else if (isAndroid) {
        redirectToPlayStore();
      } else {
        // Fallback for other platforms
        window.location.href = appStoreURL;
      }
    };
  
    const timeoutId = setTimeout(() => {
      const endTime = Date.now();
      const elapsed = endTime - startTime;
  
      if (elapsed > timeoutDuration) {
        // The app was not opened; redirect to the appropriate store
        redirectToStore();
      }
      // Else, the app was opened, do nothing
    }, timeoutDuration);
  
    openApp();
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]);
  

  return (
    <div>
      <img
        className="app-icon"
        src="https://play-lh.googleusercontent.com/i9SpGXZp6yMpxYaj1-JV419eV73ZjA6RCJh6A50vvwxlPYIpdj2TioKvu_2ISERTjw=w240-h480-rw"
        alt="Circles Verified App Icon"
      />
      <div className="container">
        <p>Welcome to Circles Verified.</p>

        <a href={appStoreURL} target="_blank" rel="noopener noreferrer" className="download-link">
          <i className="fab fa-apple"></i> iOS
        </a>

        <a href={playStoreURL} target="_blank" rel="noopener noreferrer" className="download-link">
          <i className="fab fa-android"></i> Android
        </a>
      </div>
    </div>
  );
};

export default RedirectPage;
